/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



// :root {
//     --text-color:#424242;
//     --input-text-color:#424242;
//     --input-border-color:#424242;
//     --menu-background-color:#092F87;
// }

* {
     //'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --font-family: monospace;

    --text-color:#424242;
    --input-text-color:#424242;
    --input-border-color:#424242;
    --menu-background-color:#092F87;

    font-family: var(--font-family) !important;
}


h1, h2, h3, h4, h5, h6 , p, ion-title {
    color: var(--text-color);  
    font-family: var(--font-family);  
    padding: 5px 16px;  
}

ion-split-pane {
    --side-max-width: 270px;
    --side-min-width: 270px;
}


ion-button {
    fa-icon {
        // margin-right: 10px;
    }
}

// fix arrow position
// ion-select {
//     -webkit-appearance: none;
//   -moz-appearance: none;
//   background: transparent;
//   background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 100%;
//   background-position-y: 5px;
//   border: 1px solid #dfdfdf;
//   border-radius: 2px;
//   margin-right: 2rem;
//   padding: 1rem;
//   padding-right: 2rem;
// }

ion-toast.toast-error {
    --background: red;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #fff;
}

ion-toast.toast-success {
    --background: green;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #fff;
}

ion-alert {
    // --max-width: inherit !important;
    div.alert-wrapper{
        min-width: 50% !important;
        max-width: 80% !important;
    }
}


ion-item.item-has-focus {
    ion-label{
        color: var(--input-text-color) !important;
    }
}



ion-item {

    --background:none;
    --background-activated:none;
    --background-activated-opacity:none;
    --background-focused:none;
    --background-focused-opacity:none;
    --background-hover:none;
    --background-hover-opacity:none;
    --ripple-color: transparent;
    --background-activated: transparent;



    ion-img {
        max-width: 80px !important;
        height: 80px !important;
    }

    ion-label{
        padding: 0 0 5px 0;
        margin-bottom: 5px !important;
        // margin: -10px  5px 0 5px;
    }

    ion-checkbox {
        margin: 0  5px;
    }

    
    
    ion-input, ion-select, ion-textarea, ion-checkbox {
        // --background: #373737 !important;
        color: var(--input-text-color) !important;
        // --placeholder-color: #ddd !important;
        // --placeholder-opacity: .8 !important;
        --padding-bottom: 5px !important;
        --padding-end: 5px !important;
        --padding-start: 5px !important;
        --padding-top: 5px !important;

       
    
        border: 1px solid var(--input-border-color);
    }

    ion-note[slot=error]{
        color: #fff;
        background-color: red;
        padding: 5px;
        width: -webkit-fill-available;
        display: block;
    }
}



ion-col {
    display: flex;
    flex-direction: column;

    app-address {
        width: 100%;
    }    
}

ion-col.last {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.mapCont {
    margin: 10px;

    agm-map {
    height: 400px;
    }

    .map-control{
        display: flex;
        
        div.item-command {
            min-width: 200px;

            ion-select{
                border: 1px solid var(--input-border-color);
            }

        }
        
    }
}


ion-header::after {
    background-image: none !important;
}



.display-centerd {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-justify {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.fab-command-colum {
    display: flex;
    max-width: 50px;
    // background-color: black;
    justify-content: flex-end;
    align-items: center;

    ion-fab-button{
        --padding-bottom: 0;
        margin-bottom: 0;
    }
}


// tabelle

.datatable-body-cell-label {
    ion-img {
        max-width: 150px !important;
        height: 150px !important;
    }
}

div.datatable-row-center {
    datatable-body-cell:last-of-type {
        div.datatable-body-cell-label{
            //display: flex;
            justify-content: flex-end;
        }
    }
}

ion-text[color="warning"] {
    padding: 5px;
    background-color: red;
    font-size: 16px;
}